import BasePageTemplate from 'Pages/Template/BasePageTemplate'
const EmptyPage = ({children,...props})=>{

  return (
    <BasePageTemplate>
      <h1>Nothing here</h1>
    </BasePageTemplate>
  )
}

export default EmptyPage;