// import styles from './styles.module.scss';
import {
  useAccount,
  useConnect,
  useDisconnect,
  useEnsAvatar,
  useEnsName,
  useBalance 
} from 'wagmi'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

const WalletInfo=({})=>{
  const { data: account } = useAccount()
  const { data: ensAvatar } = useEnsAvatar({ addressOrName: account?.address })
  const { data: ensName } = useEnsName({ address: account?.address })
  const { data:balance, isError:balanceError, isLoading:balanceIsLoading } = useBalance({
    addressOrName: account?.address,
  })
  const { disconnect } = useDisconnect()
  console.log('balance',balance)
  return (
    <>
      <Stack spacing={2}>
        {ensAvatar && <img src={ensAvatar} alt="ENS Avatar" />}
        {!ensAvatar && 
        <div style={{padding:'10px'}}>
          <Jazzicon diameter={100} seed={jsNumberForAddress('0x3654Bf36bB5c19F43c2ECE78B93fA38b010959dC')} />
        </div>}
        <div>
          {ensName ? `${ensName} (${account?.address})` : account?.address}
        </div>
        <div>
          {`${balance?.formatted} tPLS`}
        </div>
        {/* <div>Connected using {account?.connector?.name}</div> */}
        <Button variant="outlined" onClick={disconnect}>Disconnect</Button>
      </Stack>
    </>
  )
}

export default WalletInfo   