// import styles from './styles.module.scss';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import useGlobalState from 'Components/GlobalStateManager/index.tsx';
import SwitchVideoIcon from '@mui/icons-material/SwitchVideo';
import GppBadIcon from '@mui/icons-material/GppBad';
import Drawer from '@mui/material/Drawer';
import { useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import {
  useAccount,
} from 'wagmi'
const LeftNav=({})=>{
  const {data: account} = useAccount();
  const navigate = useNavigate();
  const {leftNavOpen,setLeftNavOpen} = useGlobalState(state=>({leftNavOpen:state.NavState.leftNavOpen,setLeftNavOpen:state.NavState.setLeftNavOpen}))

  const closeLeftNav = useCallback(()=>{
    setLeftNavOpen(false)
  },[setLeftNavOpen])

  const navTo = useCallback((path)=>{
    closeLeftNav();
    setTimeout(()=>{navigate(path)},100);
    
  },[navigate,closeLeftNav])

  const NavItem = ({path,name,icon,...props})=>{
    return (
      <>
        <ListItem>
          <ListItemButton onClick={()=>{navTo(path)}}>
            <ListItemText>
              <Typography>
                {name}
              </Typography>
            </ListItemText>
            <ListItemIcon>
              {icon}
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
      </>
    )
  }

  return (
    <Drawer
      open={leftNavOpen}
      ModalProps={{
        BackdropProps: {
          onClick:closeLeftNav
        }
      }}
    >
        <Box
          sx={{width: 250}}
        >
          <List>
            {/* HOME */}
            <NavItem path="/" name="Home" icon={<HomeIcon/>} />
            {/* OUTPUTS */}
            {/* <NavItem path="/outputs" name="Set Outputs" icon={<SwitchVideoIcon/>} /> */}
            <NavItem path="/wsdffsdf" name="404" icon={<GppBadIcon/>} />
            {true && 
              <>
                {/* <h3 sx={{paddingLeft:10}}>Logged in</h3> */}
                <Typography variant="h5" component="h5" sx={{paddingLeft:4,opacity:0.7}}>Logged In</Typography>
                <NavItem path="ar-test" name="AR Test" icon={<SwitchVideoIcon />} />
              </>
            }
          </List>
        </Box>
    </Drawer>
  )
}

export default LeftNav