// const NodePolyfillPlugin = require("node-polyfill-webpack-plugin")
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import { UltraMapDarkTheme } from 'Theme/globalTheme';
import HomePage from 'Pages/Home';
import EmptyPage from 'Pages/Empty';
import { Paper } from '@mui/material';
import {Box} from '@mui/material';
import ARTestPage from 'Pages/ARTest';

function App() {
  return (
    <ThemeProvider theme={UltraMapDarkTheme}>
      <Box className="PlushMap-root">
        <Paper sx={{width:'100vw',height:'100vh'}}>
        <Router>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="ar-test" element={<ARTestPage />} />
            <Route path="*" element={<EmptyPage />} />
          </Routes>
        </Router>
        </Paper>
      </Box>
    </ThemeProvider>
  );
}

export default App;
