
import Box from '@mui/material/Box';

import { Grid, Paper } from '@mui/material';
import TopNav from 'Components/TopNav';
import LeftNav from 'Components/LeftNav';
const BasePageTemplate = ({children,...props})=>{

  return (
    <>
      <TopNav />  
      <LeftNav />
      <Box component="main" sx={{ flexGrow: 1, m: 0, p: 4, paddingTop:'80px', height: '100vh', boxSizing: 'border-box',display:'flex',justifyContent:"center",alignItems:"center",textAlign:"center"}}>
        {children}
      </Box>
    </>
  )
}

export default BasePageTemplate;