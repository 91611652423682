import BasePageTemplate from 'Pages/Template/BasePageTemplate'
import WalletTypeSelector from 'Components/WalletTypeSelector';
import WalletInfo from 'Components/WalletInfo';
import Stack from '@mui/material/Stack';
import {
  useAccount,
} from 'wagmi'
const HomePage = ({children,...props})=>{
  const {data: account} = useAccount();
  return (
    <BasePageTemplate>
      <Stack spacing={2}>
        <h1>Home</h1>
        {account && <WalletInfo />}
        {!account && <WalletTypeSelector />}
      </Stack>
    </BasePageTemplate>
  )
}

export default HomePage;