
import { createTheme } from '@mui/material/styles';

const UltraMapDarkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});


const UltraMapLightTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

export {UltraMapDarkTheme,UltraMapLightTheme}