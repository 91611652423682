import React, { Suspense, useState } from 'react'
import { DefaultXRControllers, ARCanvas, Interactive } from '@react-three/xr'
// import './styles.module.scss';
import {Text, useGLTF} from '@react-three/drei';
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader.js';
import { useLoader, useThree, useFrame } from "@react-three/fiber";
const PulseCoin = ({position,rotation=[0,0,0],rotationSpeed=1,...rest})=>{
  const {gl} = useThree();
  const coinModel = useGLTF('/model/pulseCoin4n.glb');
  const [localRotation,setLocalRotation] = useState(rotation[1]);
  
  // debugger;
  const [pulseCoinDiffTex] = useLoader(KTX2Loader, ['tex/pulseCoinGradient.ktx2',], (loader) => {
    loader.setTranscoderPath('/basis/')
    loader.detectSupport(gl)
  })

  useFrame((state,delta,xrFrame)=>{
    setLocalRotation(localRotation + (delta*rotationSpeed))
  })

  return (
    <mesh geometry={coinModel.nodes['PulseCoinRetopo2'].geometry} position={position} rotation={[0,localRotation,0]} {...rest}>
      <meshPhysicalMaterial map={pulseCoinDiffTex} roughness={0.3} reflectivity={1} metalness={1}  />
      {/* <meshBasicMaterial map={pulseCoinDiffTex} /> */} 
    </mesh>
  )
}

export default PulseCoin;