// import styles from './styles.module.scss';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import useGlobalState from 'Components/GlobalStateManager/index.tsx';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import { useCallback } from 'react';
const TopNav=({})=>{
  const {leftNavOpen,setLeftNavOpen} = useGlobalState(state=>({leftNavOpen:state.NavState.leftNavOpen,setLeftNavOpen:state.NavState.setLeftNavOpen}))
  const toggleLeftNav = useCallback(()=>{
    setLeftNavOpen(!leftNavOpen)
  },[leftNavOpen,setLeftNavOpen])
  return (
    <AppBar 
      position="absolute"
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleLeftNav}
          >
            <MenuIcon/>
          </IconButton>
          <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
                PulseQuest
          </Typography>
        </Toolbar>
    </AppBar>
  )
}

export default TopNav