import { WagmiConfig, createClient, Chain, defaultChains, configureChains } from 'wagmi'

import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'

// import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
// import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'

const alchemyId = process.env.REACT_APP_ALCHEMY_ID;


const pulseChainTestNet = {
  id: 43_114,
  name: 'PulseChain TestNet',
  network: 'pulseChain',
  nativeCurrency: {
    decimals: 18,
    name: 'Test Pulse',
    symbol: 'tPLS',
  },
  rpcUrls: {
    default: 'https://rpc.v2b.testnet.pulsechain.com',
  },
  blockExplorers: {
    default: { name: 'Pulse Explorer', url: 'https://scan.v2b.testnet.pulsechain.com' },
  },
  testnet: true,
}

const { chains, provider, webSocketProvider } = configureChains([pulseChainTestNet], [
  alchemyProvider({ alchemyId }),
  publicProvider(),
])

// Set up client
const client = createClient({
  autoConnect: true,
  connectors: [
    // new MetaMaskConnector({ chains }),
    // new CoinbaseWalletConnector({
    //   chains,
    //   options: {
    //     appName: 'wagmi',
    //   },
    // }),
    // new WalletConnectConnector({
    //   chains,
    //   options: {
    //     qrcode: true,
    //   },
    // }),
    new InjectedConnector({
      chains,
      options: {
        name: 'Injected wallet',
        shimDisconnect: true,
      },
    }),
  ],
  provider,
  webSocketProvider,
})

// Pass client to React Context Provider
function WagmiWrapper({children}) {
  console.log('wagmiWrapper')
  return (
    <WagmiConfig client={client}>
      {children}
    </WagmiConfig>
  )
}

// const WagmiWrapper = ""

export default WagmiWrapper;