import { useConnect } from 'wagmi'
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
const WalletTypeSelector = () => {
  const { connect, connectors, error, isConnecting, pendingConnector } =
    useConnect()

  return (
    <Stack spacing={2}>
      {connectors.map((connector) => (
        <Button
          variant="outlined"
          disabled={!connector.ready}
          key={connector.id}
          onClick={() => connect(connector)}
        >
          {`Connect `}
          {/* {connector.name} */}
          {` wallet`}
          {!connector.ready && ' (unsupported)'}
          {isConnecting &&
            connector.id === pendingConnector?.id &&
            ' (connecting)'}
        </Button>
      ))}

      {error && <div>{error.message}</div>}
    </Stack>
  )
}

export default WalletTypeSelector;