// import styles from './styles.module.scss';
import { Typography } from '@mui/material'
import BasePageTemplate from 'Pages/Template/BasePageTemplate'
import { DefaultXRControllers, ARCanvas, Interactive } from '@react-three/xr'
import { Environment } from '@react-three/drei'
import PulseCoin from 'Components/ARTest/PulseCoin'
const ARTestPage=({})=>{
  return (
    <BasePageTemplate>
      <ARCanvas>
        <ambientLight intensity={0.1}/>
        <pointLight position={[10, 10, 10]} />
        {/* <Button position={[0, 0.1, -0.2]} /> */}
        <PulseCoin position={[0, 0.1, -0.5]} scale={[0.07,0.07,0.07]} rotation={[0.0,-90,0]} rotationSpeed={1} />
        <Environment preset="warehouse" />
        {/* <DefaultXRControllers /> */}
      </ARCanvas>
    </BasePageTemplate>
  )
}

export default ARTestPage