import create from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware'
import produce from 'immer'

const useGlobalState = create(subscribeWithSelector((set,get)=>({
  NavState: {
    leftNavOpen: false,
    setLeftNavOpen: (newLeftNavState)=>set(produce(state=>{
      state.NavState.leftNavOpen = newLeftNavState;
    })),
    leftNavContent: null,
    rightNavOpen: false,
    setRightNavOpen: (newRightNavState)=>set(produce(state=>{
      state.NavState.rightNavOpen = newRightNavState;
    })),
    rightNavContent: null
  },
})));




export default useGlobalState